
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.surveys-info-block {
  &__field {
    display: flex;
    align-items: center;
    gap: $base-gutter / 2;
    &:not(:last-child) {
      margin-bottom: $gap /2;
    }
  }
  &__label {
    width: 90px;
    flex-shrink: 0;
    color: $color-grayscale-60;
  }
  &__priority {
    border-radius: $base-border-radius;
    padding: 2px 8px;
    color: $color-white;
    font-weight: 500;
  }
  &__user {
    display: flex;
    align-items: center;
    gap: $base-gutter / 3;
  }
}
